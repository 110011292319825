const { TYPES } = require('./constants'); // SELECT_FIELD_MODE
//

const arr = [
  {
    id: 'seq_id',
    label: 'ID',
    type: TYPES.SYSTEM,
  },
  {
    id: 'from_mail',
    label: 'From',
    type: TYPES.INPUT,
    // tab_size:100,
  },
  {
    id: 'to_mail',
    label: 'To',
    type: TYPES.INPUT,
    // tab_size:200,
  },
  {
    id: 'subject_mail',
    label: 'Subject',
    type: TYPES.INPUT,
    // tab_size:200,
  },
  {
    id: 'receive_time',
    label: 'Time',
    type: TYPES.DATE_PICKER,
    show_time: true,
    format: 'YYYY/MM/DD HH:mm',
  },
  {
    id: 'body_mail',
    label: 'Body',
    type: TYPES.TEXT_AREA,
    tab_size: 500,
  },
];
const ret = {};
let s = 1;
arr.forEach((e) => {
  /* eslint-disable no-alert, no-console */
  const ex_pattern = '^[a-z][a-z0-9_]+$';
  if (!RegExp(ex_pattern).test(e.id)) { alert(`Invalid field ${e.id}`); }
  e.tab_label = e.tab_label || e.label;
  e.seq_id = s;
  s += 1;
  ret[e.id] = e;
  /* eslint-enable no-alert, no-console */
});
ret.array = arr;
module.exports.fields = ret;
module.exports.filed_types = TYPES;
