<template>
  <div class="modal fade " ref="modalConfirmation" id="staticBackdrop" data-bs-backdrop="static"
    data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">{{local_config.title}}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12" style="" v-html="local_config.body"></div>
          </div>
        </div>
        <div class="modal-footer">
          <button v-for="b in local_config.buttons"
          :data-cy="b.cy || 'btn_' + b.label"
          type="button"
          :key="b.label"
          :class="'btn ' + b.class"
          @click="onAction(b.action)">{{b.label}}</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import mixinFormController from '@/mixins/form_controller';
// import _ from 'lodash';
import mixinLayoutComponents from '@/mixins/layout_components';
import { Modal } from 'bootstrap';

export default {
  name: 'confirmation-modal',
  data() {
    return {
      api_name: 'confirmation',
      name: 'confirmation-modal',
      modalElem: null,
      local_config: {
        dialog_class: '',
        title: '...',
        body: '...',
        buttons: [],
      },
    };
  },

  mixins: [mixinLayoutComponents],
  components: {},
  computed: {},
  methods: {
    showModal(config) {
      this.local_config.title = config?.title || '...';
      this.local_config.body = config?.body || '...';
      this.local_config.buttons = config?.buttons || [];
      this.local_config.dialog_class = config?.dialog_class || '';
      console.log('TEST DEBUG 240203 showModal: ', { local_config: this.local_config });

      this.modalElem.show();
    },
    onAction(action) {
      this.modalElem.hide();
      action();
    },
  },
  mounted() {
    this.modalElem = new Modal(this.$refs.modalConfirmation);
    // {
    //   title: 'Title is here',
    //   body: 'Body is here',
    //    dialog_class: 'modal-xl',
    //   buttons:[
    //     {label:'Cancel', class:'btn-secondary', action:()=>{}},
    //     {label:'Confirm', class:'btn-primary', action:()=>{}}
    //   ],
    // };
  },
  props: [],
  beforeCreate() {
    console.log(`${this.name} beforeCreate`);
  },
  created() {
    console.log(`${this.name} created`);
  },
  beforeMount() {
    console.log(`${this.name} beforeMount`);
  },
  beforeUpdate() {
    console.log(`${this.name} beforeUpdate`);
  },
  updated() {
    console.log(`${this.name} updated`);
  },
  beforeUnmount() {
    console.log(`${this.name} beforeUnmount`);
  },
  unmounted() {
    console.log(`${this.name} unmounted`);
  },
  errorCaptured() {
    console.log(`${this.name} errorCaptured`);
  },
  renderTracked() { },
  renderTriggered() {
    console.log(`${this.name} renderTriggered`);
  },
  activated() {
    console.log(`${this.name} activated`);
  },
  deactivated() {
    console.log(`${this.name} deactivated`);
  },
};
</script>
<style scoped></style>
