<template>
  <div class="modal fade " ref="modalDictionary" id="staticBackdrop" data-bs-backdrop="static"
    data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">Fake mail </h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12" style="">
              <label style="font-weight: bold;">From:</label> {{model.from_mail}}
            </div>
            <div class="col-12" style="">
              <label style="font-weight: bold;"> To:</label> {{model.to_mail}}
            </div>
          </div>
          <div class="row">
            <div class="col-12" style="">
              <label style="font-weight: bold;"> Subject:</label> {{model.subject_mail}}
            </div>
          </div>
          <div class="row">
            <div class="col-12" style="">
              <label style="font-weight: bold;">Body:</label>
              <br>
              <span v-html="model.body_mail"></span>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" style="">Close</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import mixinFormController from '@/mixins/form_controller';
import mixinLayoutComponents from '@/mixins/layout_components';
// import _ from 'lodash';
import { Modal } from 'bootstrap';
import { fields } from '../../../../../sender/api/rules/fields_fake_mail';

export default {
  name: 'manager_fake_mail-form',
  data() {
    return {
      local_fields_ref_a: false,
      local_fields_ref_b: false,
      api_name: 'manager_fake_mail',
      name: 'manager_dictionary-form',
      modalElem: null,
      local_id: null,
      onClose: null,
      model: {
        from_mail: '',
        to_mail: '',
        body_mail: '',
        subject_mail: '',
        receive_time: '',
      },
    };
  },

  mixins: [mixinLayoutComponents, mixinFormController],
  components: {},
  computed: {
    localId: {
      get() {
        return this.local_id;
      },
      set(v) {
        this.local_id = v;
      },
    },
    seqId: {
      get() {
        return this.local_id;
      },
      set(v) {
        this.local_id = v;
      },
    },
    formFieldsSideA() {
      if (this.local_fields_ref_a === false) {
        this.local_fields_ref_a = this.setFormDefaultFields(['from_mail', 'to_mail', 'to_mail', 'body_mail', 'subject_mail', 'receive_time'], fields);
      }
      return this.local_fields_ref_a;
    },
  },
  methods: {
    showModal(id) {
      console.log('TEST DEBUG 240102 (72 at fake_mail-modal.vue)[00:57]: ', { id });
      this.localId = id;
      // _.find(this.formFieldsSideA, (f) => f.id === 'from_date').ref_field.setValue(new Date());
      // _.find(this.formFieldsSideA, (f) => f.id === 'customer_id').ref_field.setValue('');
      // _.find(this.formFieldsSideA, (f) => f.id === 'notes').ref_field.setValue('');
      this.loadFormData(id);
      this.modalElem.show();
    },
    loadFormData(id) {
      console.log('TEST DEBUG 240102 (81 at fake_mail-modal.vue)[00:56]: ', { localID: this.localId, id });
      this.commonLoadRecord({ force_seq_id: id }, (err, result) => {
        // this.setFormFields(result?.data || result);
        this.model = result?.data || result;
        console.log('Load loadFormData ', this.localId, { err, result });
      });
    },
  },
  props: ['id'],
  beforeCreate() {
    console.log(`${this.name} beforeCreate`);
  },
  created() {
    console.log(`${this.name} created`);
  },
  beforeMount() {
    console.log(`${this.name} beforeMount`);
  },
  mounted() {
    console.log(`${this.name} mounted`);
    this.modalElem = new Modal(this.$refs.modalDictionary);
    this.is_new = false;
  },
  beforeUpdate() {
    console.log(`${this.name} beforeUpdate`);
  },
  updated() {
    console.log(`${this.name} updated`);
  },
  beforeUnmount() {
    console.log(`${this.name} beforeUnmount`);
  },
  unmounted() {
    console.log(`${this.name} unmounted`);
  },
  errorCaptured() {
    console.log(`${this.name} errorCaptured`);
  },
  renderTracked() { },
  renderTriggered() {
    console.log(`${this.name} renderTriggered`);
  },
  activated() {
    console.log(`${this.name} activated`);
  },
  deactivated() {
    console.log(`${this.name} deactivated`);
  },
};
</script>
<style scoped></style>
