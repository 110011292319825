// EDIT THIS FILE ONLY AT SENDER
module.exports = {
  ACL_ACTION: {
    READ: 'READ',
    ADD: 'ADD',
    EDIT: 'EDIT',
    DELETE: 'DELETE',
    EXPORT: 'EXPORT',
  },
  HISTORY_MODE: {
    NEW: 1,
    UPDATE: 2,
    DELETE: 3,
    UPDATE_APPLICATION: 4,
  },
  TYPES: {
    SELECT_BOX: 'select',
    RADIO_BUTTON: 'radio_button',
    INPUT: 'input',
    DATE_PICKER: 'date_picker',
    CURRENT_TIME: 'current_time',
    PASSWORD: 'password',
    TEXT_AREA: 'text_area',
    CHECKBOX: 'checkbox',
    SPACE: 'space',
    BUTTON: 'button',
    EMAIL: 'email',

    SELECT_BOX2: 'select2',
    SELECT_BOX3: 'select3',
    SELECT_BOX4: 'select4',
    SYSTEM: 'system',
  },
  SELECT_FIELD_MODE: {
    ARRAY: 'ARRAY',
    LIST: 'LIST',
    API: 'API',
    FORM_CONTROL: 'FORM_CONTROL',
  },
  IDS: {
    ADD_NEW_RECORD_ID: `${Number.MAX_SAFE_INTEGER}`,
  },
  OWNER_TYPE: {
    SENDER: 1,
    CONTACT: 2,
    MFA_ADDRESS: 3,
    ANONYMOUS: 4,
    SESSION: 5,
    UNDEFINED: 9,
  },
  LOG_TYPE: {
    // Sender logs start from 0
    LOGIN: 1,
    LOGOUT: 2,
    CHANGE_OWN_PASSWORD: 3,
    CREATE_USER: 5,
    CHANGE_OTHER_USER_PASSWORD: 6,

    CHANGE_OWN_DATA_EMAIL: 8,
    CHANGE_OWN_DATA_NAME: 9,

    CHANGE_OTHER_USER_NAME: 21,
    CHANGE_OTHER_USER_EMAIL: 22,
    CHANGE_OTHER_USER_STATUS: 23,
    CHANGE_OTHER_USER_ACCESS_LEVEL: 24,
    CHANGE_OTHER_USER_EMAIL_NOTIFICATION: 25,

    DELETE_OTHER_USER: 30,

    SAVE_URL: 100,
    FILE_UPLOADED_TO_URL: 101,
    FILE_DELETED_FROM_URL: 102,
    DOWNLOAD_URL: 103,

    // Recipient logs start from 100000
    RECIPIENT_FILE_UPLOADED_TO_URL: 100001,
    RECIPIENT_DOWNLOAD_URL: 100002,
    // Manager logs start from 900000
    MANAGER_DELETE_USER: 900020, // FIXME: Not yet implemented
  },
};
// EDIT THIS FILE ONLY AT SENDER
