const { TYPES, SELECT_FIELD_MODE } = require('./constants');
//

const arr = [
  { id: 'id', label: 'Unique Key', type: TYPES.SYSTEM },

  {
    id: 'user_name',
    label: 'User Name',
    type: TYPES.INPUT,
  },

  {
    id: 'auth_password',
    label: 'Password',
    type: TYPES.PASSWORD,
  },

  {
    id: 'display_name',
    label: 'Display Name',
    type: TYPES.INPUT,
  },
  {
    id: 'auth_method',
    label: 'auth_method',
    type: TYPES.INPUT,
  },
  {
    id: 'email',
    label: 'email',
    type: TYPES.INPUT,
  },
  {
    id: 'pass_changed_date',
    label: 'pass_changed_date',
    type: TYPES.INPUT,
  },
  {
    id: 'user_status',
    label: 'Status',
    options_mode: SELECT_FIELD_MODE.LIST,
    list: [
      { label: 'Enable', value: '1' },
      { label: 'Disable', value: '0' },
    ],
    type: TYPES.SELECT_BOX,
  },
  {
    id: 'user_level',
    label: 'Access Level',
    options_mode: SELECT_FIELD_MODE.LIST,
    list: [
      { label: 'User', value: '1' },
      { label: 'Account Manager', value: '5' },
      { label: 'Master Admin', value: '9' },
    ],
    type: TYPES.SELECT_BOX,
  },
];
const ret = {};
let s = 1;
arr.forEach((e) => {
  /* eslint-disable no-alert, no-console */
  const ex_pattern = '^[a-z][a-z0-9_]+$';
  if (!RegExp(ex_pattern).test(e.id)) { alert(`Invalid field ${e.id}`); }
  e.tab_label = e.tab_label || e.label;
  e.seq_id = s;
  s += 1;
  ret[e.id] = e;
  /* eslint-enable no-alert, no-console */
});
ret.array = arr;
module.exports.fields = ret;
module.exports.filed_types = TYPES;
