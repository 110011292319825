<template>
    <nav class="navbar navbar-expand-lg navbar-light bg-light fixed-top">
        <div class="container-fluid" style="background:rgb(255, 255, 255);border-bottom: 2px #6666FF double;padding-bottom:20px;">

          <div style="float:left;margin-left:100px;">
            <ul class="nav ">
              <li class="nav-item">
                <a class=" " href="/" style="padding-top: 0px;margin-top: 0px;">
                <img
                style="height: 60px;"
                src="/img/logo_h2.png" alt="ホーム" />
                </a>
              </li>
              <li class="nav-item">
                <span v-if="menu_id=='accounts'" class="nav-link btn" data-cy="accounts-link">Accounts</span>
                <a v-else class="nav-link btn btn-primary" href="/accounts" data-cy="accounts-button">Accounts</a>
              </li>
              <li class="nav-item">
                <span v-if="menu_id=='dictionary'" class="nav-link btn" data-cy="dictionary-link">Dictionary</span>
                <a v-else class="nav-link btn btn-primary" href="/dictionary" data-cy="dictionary-button">Dictionary</a>
              </li>
              <li class="nav-item">
                <span v-if="menu_id=='fake_mail'" class="nav-link btn" data-cy="fake-mail-link">Fake mail</span>
                <a v-else class="nav-link btn btn-primary" href="/fake_mail" data-cy="fake-mail-button">Fake mail</a>
              </li>
              <!-- <li class="nav-item">
                <span v-if="menu_id=='new'" class="nav-link  btn" >Tenants</span>
                <a v-else class="nav-link btn" @click.prevent="createNewAndGo">Tenants</a>
              </li> -->
            </ul>
          </div>
          <div style="float: right;margin-right:100px;">
            <ul class="nav ">
              <!-- <li class="nav-item">
                <a class="nav-link" href="/settings">設定</a>
              </li> -->
              <li class="nav-item">
                <a class="nav-link" href="/logout" data-cy="logout-link">ログアウト</a>
              </li>
              <li class="nav-item">
                <a class="nav-link disabled" href="/" data-cy="display-name-link"><i class="bi bi-person-fill"></i> {{displayName }}</a>
                <span></span>
              </li>
            </ul>
        </div>

        </div>
    </nav>

  <main style="padding-top: 100px;padding-bottom: 100px;">
    <slot></slot> <!-- Page-specific content will go here -->
  </main>

    <footer class="footer mt-auto py-0 bg-light fixed-bottom" style="border-top: 2px #6666FF double;padding-top:20px;background-color:orange!important;">
        <div class="container-fluid" style="background-color:orange;">
          <div style="float:left;margin-left:100px;background-color:orange;"></div>
          <div style="float:right;margin-right:100px;background-color:orange;">
            Management area
            <ul class="nav " v-show="false">
              <li class="nav-item">
                <a class="nav-link nav-link-footer"  style="background-color: transparent;color:#6666FF;"  href="#" data-cy="about-link">About</a>
              </li>
              <li class="nav-item">
                <a class="nav-link nav-link-footer"   style="background-color: transparent;color:#6666FF;" href="#" data-cy="privacy-link">Privacy</a>
              </li>
            </ul>
            </div>
        </div>
    </footer>
</template>
<script>

// import PageHeader from '@/components/header.vue';

export default {
  data() {
    return {

    };
  },

  computed: {
    displayName() {
      console.log('TEST DEBUG 231015 (29 at menu.vue)[18:11]: Load displayName', { });
      return localStorage.getItem('display_name');
    },
  },
  components: {

  },
  methods: {
    createNewAndGo() {
      const api_url = '/attach/new';
      const body = {};
      this.$ajax.post(api_url, body, (err, result) => {
        console.log({ err, result, body });
        if (err) {
          this.$notify({
            type: 'error',
            title: '予期しないエラー',
            text: err,
          });
        } else {
          this.$router.push(`/new/${result.data.id}`);
        }
      });
    },
  },
  props: ['menu_id'],
  beforeCreate() {
    console.log(`${this.name} beforeCreate`);
  },
  created() {
    console.log(`${this.name} created`);
  },
  beforeMount() {
    console.log(`${this.name} beforeMount`);
  },
  mounted() {
    console.log(`${this.name} mounted`);
  },
  beforeUpdate() {
    console.log(`${this.name} beforeUpdate`);
  },
  updated() {
    console.log(`${this.name} updated`);
  },
  beforeUnmount() {
    console.log(`${this.name} beforeUnmount`);
  },
  unmounted() {
    console.log(`${this.name} unmounted`);
  },
  errorCaptured() {
    console.log(`${this.name} errorCaptured`);
  },
  renderTracked() {

  },
  renderTriggered() {
    console.log(`${this.name} renderTriggered`);
  },
  activated() {
    console.log(`${this.name} activated`);
  },
  deactivated() {
    console.log(`${this.name} deactivated`);
  },
};
</script>
<style scoped>
a.nav-link {
  margin-left: 5px;
  color: #6666FF;
  background-color: transparent;
  border-radius: 9px;
}
a.nav-link:hover {

  color: white;
  background-color: #4e56e1;
  border-radius: 9px;
}

a.nav-link-footer:hover {
  text-decoration: underline;
  font-weight: 500;
}
span.nav-link {
  margin-left: 5px;
  color: white;
  background-color: #6666FF;
  border-radius: 9px;
}
</style>
