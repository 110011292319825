<template>
  <MainLayout menu_id="tenant">
    <ManagerContainer>
      <AgTable
          :useAddNew="true"
          :useSearch="true"
          :allFields="fieldList"
          :tabFields="tabFieldList"
          :apiName="api_name"
          :apiRequestOptions="{}"
          @onRowSelected="onRowSelected"
          @onAddNew="onNew"
        ></AgTable>
    </ManagerContainer>
  </MainLayout>
</template>
<script>
// import moment from 'moment';
// import PageHeader from '@/components/header.vue';
import ManagerContainer from '@/components/manager-container.vue';
import MainLayout from '@/layouts/mainLayout.vue';
import agListController from '@/mixins/ag-list-controller';
import _ from 'lodash';
import datasource from '@/mixins/datasource';
import AgTable from '@/components/ag-table.vue';
import { fields } from '../../../../sender/api/rules/fields_tenant';

export default {
  data() {
    return {
      name: 'tenant',
      api_name: 'manager_tenant',
      // detail_page: 'attach-form',
      loaded_data: [],
      is_loaded: false,
      selected_id: null,
    };
  },
  routes: [{
    // path: '/menu', name: 'Menu', component: this, meta: { requiresAuth: true },
    path: '/accounts', name: 'Tenants', component: this, meta: { requiresAuth: true },
  }],
  computed: {
    selectedId: {
      get() {
        return this.selected_id;
      },
      set(v) {
        this.selected_id = v;
      },
    },
    fieldList() {
      return fields;
    },
    tabFieldList() {
      const list = [
        _.extend(fields.id, {}),
        _.extend(fields.tenant_name, {}),
        _.extend(fields.tenant_status, {
          forceCellRenderer: (params) => ((parseInt(params.value) === 1) ? 'Active' : 'Inactive'),
        }),
        _.extend(fields.tenant_description, { }),
      ];
      return list;
    },
    searchFieldList() {
      return [
        _.extend(fields.to_mail, {}),
      ];
    },
    pageMode() {
      return this.$route.params.mode;
    },
    displayName() {
      console.log('TEST DEBUG 231015 (29 at menu.vue)[18:11]: Load displayName', {});
      return localStorage.getItem('display_name');
    },
    isLoaded: {
      get() {
        return this.is_loaded;
      },
      set(v) {
        this.is_loaded = v;
      },
    },
    loadedData: {
      get() {
        return this.loaded_data;
      },
      set(v) {
        this.loaded_data = v;
      },
    },
  },
  mixins: [agListController, datasource],
  components: {
    MainLayout, ManagerContainer, AgTable,
  },
  methods: {
    onNew() {
      this.$router.push('/accounts/account/new');
    },
    onRowSelected(ev) {
      return this.$router.push(`/accounts/account/${ev.node.data.id}`);
    },
  },
  props: [],
  beforeCreate() {
    console.log(`${this.name} beforeCreate`);
  },
  created() {
    console.log(`${this.name} created`);
  },
  beforeMount() {
  },
  mounted() {
    console.log(`${this.name} mounted`);
  },
  beforeUpdate() {
    console.log(`${this.name} beforeUpdate`);
  },
  updated() {
    console.log(`${this.name} updated`);
  },
  beforeUnmount() {
    console.log(`${this.name} beforeUnmount`);
  },
  unmounted() {
    console.log(`${this.name} unmounted`);
  },
  errorCaptured() {
    console.log(`${this.name} errorCaptured`);
  },
  renderTracked() {

  },
  renderTriggered() {
    console.log(`${this.name} renderTriggered`);
  },
  activated() {
    console.log(`${this.name} activated`);
  },
  deactivated() {
    console.log(`${this.name} deactivated`);
  },
};
</script>
<style >

.btn-primary {
  background-color: #6666FF;
  /* Primary color */
  border-color: #5a62d2;
  /* Slightly darker shade for the border */
}

.btn-primary:hover {
  background-color: #6268e0;
  /* Slightly darker shade for hover */
  border-color: #4f58c2;
  /* Optionally, you could also darken the border color slightly on hover */
}

.inactive_tab {
  color: #6666FF;
}

a.attach_link {
  color: #6666FF;
  font-size: 16px;
  ;
}

.ag-header-cell-text {
  font-size: 16px !important;
  font-weight: bold !important;
}

.ag-row {
  font-size: 16px !important;
  height: 35px !important;
  color: #6666FF !important;
}

.ag-cell {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

.ag-theme-bootstrap .ag-ltr .ag-cell {
  border: none !important;
}

.ag-row:hover {
  background-color: #6666FF !important;
  color: white !important;

}

.ag-row-selected {
  background-color: #6666FF !important;
  color: white !important;
}

.ag-theme-bootstrap {
  border: 1px solid #ccc !important;
}

.ag-header-cell:not(:first-child) {
  border-left: 1px solid #ddd !important;
}</style>
