const { TYPES, SELECT_FIELD_MODE } = require('./constants');
//

const arr = [
  {
    id: 'id',
    label: 'ID',
    type: TYPES.INPUT,
  },
  {
    id: 'tenant_name',
    label: 'Name',
    type: TYPES.INPUT,
    // tab_size:100,
  },
  {
    id: 'cname',
    label: 'CNAME',
    type: TYPES.INPUT,
    // tab_size:100,
  },
  {
    id: 'tenant_status',
    label: 'Status',
    options_mode: SELECT_FIELD_MODE.LIST,
    list: [
      { label: 'Active', value: 1 },
      { label: 'Inactive', value: 0 },
    ],
    type: TYPES.SELECT_BOX,
  },
  {
    id: 'tenant_description',
    label: 'Description',
    type: TYPES.INPUT,
    // tab_size:200,
  },
];
const ret = {};
let s = 1;
arr.forEach((e) => {
  /* eslint-disable no-alert, no-console */
  const ex_pattern = '^[a-z][a-z0-9_]+$';
  if (!RegExp(ex_pattern).test(e.id)) {
    alert(`Invalid field ${e.id}`);
  }
  e.tab_label = e.tab_label || e.label;
  e.seq_id = s;
  s += 1;
  ret[e.id] = e;
  /* eslint-enable no-alert, no-console */
});
ret.array = arr;
module.exports.fields = ret;
module.exports.filed_types = TYPES;
