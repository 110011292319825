const { TYPES } = require('./constants'); // SELECT_FIELD_MODE
//

const arr = [
  { id: 'id', label: 'Unique Key', type: TYPES.SYSTEM },

  {
    id: 'key_id',
    label: 'Setting Key',
    type: TYPES.INPUT,
  },
  {
    id: 'key_value',
    label: 'Setting Value',
    type: TYPES.INPUT,
  },
  {
    id: 'user_id',
    label: 'For user',
    type: TYPES.INPUT,
  },
];
const ret = {};
let s = 1;
arr.forEach((e) => {
  /* eslint-disable no-alert, no-console */
  const ex_pattern = '^[a-z][a-z0-9_]+$';
  if (!RegExp(ex_pattern).test(e.id)) { alert(`Invalid field ${e.id}`); }
  e.tab_label = e.tab_label || e.label;
  e.seq_id = s;
  s += 1;
  ret[e.id] = e;
  /* eslint-enable no-alert, no-console */
});
ret.array = arr;
module.exports.fields = ret;
module.exports.filed_types = TYPES;
