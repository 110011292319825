<template>
  <MainLayout menu_id="home">

  <div class="row">
    <div class="col-2" ></div>
    <div class="col-8">
      <!-- <h1>e-attach</h1> -->
    </div>
  </div>
  <div class="row">
    <div class="col-2" ></div>
    <div class="col-8" style="margin-top:50px">
    <h2>e-attach management tool</h2>
    <br>
    This tool is dedicated to the management of e-attach.
    </div>
  </div>
  </MainLayout>
</template>
<script>

import MainLayout from '@/layouts/mainLayout.vue';

export default {
  data() {
    return {

    };
  },
  routes: [{
    // path: '/menu', name: 'Menu', component: this, meta: { requiresAuth: true },
    path: '/', name: 'Home', component: this, meta: { requiresAuth: true },
  }],
  computed: {

  },

  components: { MainLayout },
  methods: {

  },
  props: [],
  beforeCreate() {
    console.log(`${this.name} beforeCreate`);
  },
  created() {
    console.log(`${this.name} created`);
  },
  beforeMount() {
    console.log(`${this.name} beforeMount`);
  },
  mounted() {
    console.log(`${this.name} mounted`);
  },
  beforeUpdate() {
    console.log(`${this.name} beforeUpdate`);
  },
  updated() {
    console.log(`${this.name} updated`);
  },
  beforeUnmount() {
    console.log(`${this.name} beforeUnmount`);
  },
  unmounted() {
    console.log(`${this.name} unmounted`);
  },
  errorCaptured() {
    console.log(`${this.name} errorCaptured`);
  },
  renderTracked() {

  },
  renderTriggered() {
    console.log(`${this.name} renderTriggered`);
  },
  activated() {
    console.log(`${this.name} activated`);
  },
  deactivated() {
    console.log(`${this.name} deactivated`);
  },
};
</script>
<style scoped>

.btn-primary {
    background-color: #6666FF; /* Primary color */
    border-color: #5a62d2; /* Slightly darker shade for the border */
}

.btn-primary:hover {
    background-color: #6268e0; /* Slightly darker shade for hover */
    border-color: #4f58c2; /* Optionally, you could also darken the border color slightly on hover */
}

</style>
