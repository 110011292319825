import { createStore } from 'vuex';

export default createStore({
  state: {
    current_row1: {},
    current_field_style1: {},
    is_editing_new1: false,
    current_editing1: [],
    last_filter_options1: {},
    current_row2: {},
    current_field_style2: {},
    is_editing_new2: false,
    current_editing2: [],
    last_filter_options2: {},
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  },
});
