<template>
  <div class="container" style="margin-top:20px;">
    <div class="row">

      <div class="col-12">
        <div :style="(center)?'display: grid; place-items: center;':''">
          <slot></slot>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'ManagerContainer',
  props: {
    msg: String,
    center: Boolean,
  },
};
</script>

<style scoped></style>
