<template>
  <MainLayout menu_id="fake_mail">
    <div class="row">
      <!-- <div class="col-1"></div> -->
      <div class="col-1"></div>
      <div class="col-10">
        <div style="margin-top: 20px;width:100%;display: inline-block;">
          <button @click.prevent="onReload" class="btn btn-primary" data-cy="reload-button">Reload</button>
          <input type="text" class="form-control" style="width:200px;float: right;"
            @input="localSearchControlChangedDatasource" placeholder="検索" aria-label="検索"
            aria-describedby="basic-addon1" data-cy="search-input" />
        </div>
        <ag-grid-vue style="width: 100%; height: 325px; margin-top: 7px;" class="ag-theme-bootstrap"
          :gridOptions="gridOptions">
        </ag-grid-vue>

        <fakeMailModal ref="fakeMailModal" :id="selectedId"></fakeMailModal>
      </div>
    </div>
  </MainLayout>
</template>
<script>
// import moment from 'moment';
// import PageHeader from '@/components/header.vue';
import MainLayout from '@/layouts/mainLayout.vue';
import agListController from '@/mixins/ag-list-controller';
import _ from 'lodash';
import datasource from '@/mixins/datasource';
import fakeMailModal from '@/components/modals/fake-mail-modal';
import { fields } from '../../../../sender/api/rules/fields_fake_mail';

export default {
  data() {
    return {
      name: 'fake_mail',
      api_name: 'manager_fake_mail',
      // detail_page: 'attach-form',
      loaded_data: [],
      is_loaded: false,
      selected_id: null,
    };
  },
  routes: [{
    // path: '/menu', name: 'Menu', component: this, meta: { requiresAuth: true },
    path: '/fake_mail', name: 'FakeMail', component: this, meta: { requiresAuth: true },
  }],
  computed: {
    selectedId: {
      get() {
        return this.selected_id;
      },
      set(v) {
        this.selected_id = v;
      },
    },
    fieldList() {
      return fields;
    },
    tabFieldList() {
      const list = [
        _.extend(fields.seq_id, {}),
        _.extend(fields.to_mail, {}),
        _.extend(fields.receive_time, {}),
        _.extend(fields.body_mail, {
          cellRenderer: (params) => params.value,
        }),
      ];
      return list;
    },
    searchFieldList() {
      return [
        _.extend(fields.to_mail, {}),
      ];
    },
    pageMode() {
      return this.$route.params.mode;
    },
    displayName() {
      console.log('TEST DEBUG 231015 (29 at menu.vue)[18:11]: Load displayName', {});
      return localStorage.getItem('display_name');
    },
    isLoaded: {
      get() {
        return this.is_loaded;
      },
      set(v) {
        this.is_loaded = v;
      },
    },
    loadedData: {
      get() {
        return this.loaded_data;
      },
      set(v) {
        this.loaded_data = v;
      },
    },
  },
  mixins: [agListController, datasource],
  components: { MainLayout, fakeMailModal },
  methods: {
    onReload() {
      this.localSearchControlChangedDatasource({ target: { value: '' } });
    },
    localSearchControlChangedDatasource(ev) {
      this.searchControlChangedDatasource({

        is_block_info: true,
        1: {
          block: '1',
          field: 'to_mail',
          value: ev.target.value,
          ct: 'v',
          ca: false,
          t: 'input',
        },
        2: false,
        3: false,
        j1: 'OR',
        j2: 'OR',
        b2_disabled: false,
        b3_disabled: true,

      });
    },
    onRowSelected(ev) {
      this.selectedId = ev.node.data.seq_id;
      setTimeout(() => {
        console.log('TEST DEBUG 240102 (140 at fake_mail.vue)[00:47]: this.selectedId ', this.selectedId);
        this.$refs.fakeMailModal.onClose = (answer) => {
          const result = answer.data;
          console.log('TEST DEBUG 240102 (143 at fake_mail.vue)[01:37]: ', { result });
          this.gridOptions.api.forEachNode((node) => {
            if (node && node.data && result && node.data.seq_id === result.seq_id) {
              console.log('TEST DEBUG 240102 (143 at fake_mail.vue)[01:43]: ', { seq_id: result.seq_id });
              Object.keys(result).forEach((key) => {
                if (result[key] !== node.data[key]) {
                  node.data[key] = result[key];
                }
              });
            }
          });
          this.gridOptions.api.refreshView();
          this.gridOptions.api.deselectAll();
        };

        this.$refs.fakeMailModal.showModal(this.selectedId);
      }, 200);
    },
    createColumnDefs() {
      return this.commonCreateColumnDefs({ show_details: false, show_checkbox: false, force_sort: { id: 'seq_id', direction: 'desc' } });
    },
    onOpenAttach(attach_id) {
      console.log('TEST DEBUG 231028 (84 at history.vue)[14:59]: ', { attach_id });
    },
  },
  props: [],
  beforeCreate() {
    console.log(`${this.name} beforeCreate`);
  },
  created() {
    console.log(`${this.name} created`);
  },
  beforeMount() {
    this.api_request_options = { mode: this.$route.params.mode };
    this.gridOptions = _.extend(this.commonGridOptions, {});
    this.initDatasource({});
  },
  mounted() {
    this.gridOptions.api.sizeColumnsToFit();
    console.log(`${this.name} mounted`);
  },
  beforeUpdate() {
    console.log(`${this.name} beforeUpdate`);
  },
  updated() {
    console.log(`${this.name} updated`);
  },
  beforeUnmount() {
    console.log(`${this.name} beforeUnmount`);
  },
  unmounted() {
    console.log(`${this.name} unmounted`);
  },
  errorCaptured() {
    console.log(`${this.name} errorCaptured`);
  },
  renderTracked() {

  },
  renderTriggered() {
    console.log(`${this.name} renderTriggered`);
  },
  activated() {
    console.log(`${this.name} activated`);
  },
  deactivated() {
    console.log(`${this.name} deactivated`);
  },
};
</script>
<style >

.btn-primary {
  background-color: #6666FF;
  /* Primary color */
  border-color: #5a62d2;
  /* Slightly darker shade for the border */
}

.btn-primary:hover {
  background-color: #6268e0;
  /* Slightly darker shade for hover */
  border-color: #4f58c2;
  /* Optionally, you could also darken the border color slightly on hover */
}

.inactive_tab {
  color: #6666FF;
}

a.attach_link {
  color: #6666FF;
  font-size: 16px;
  ;
}

.ag-header-cell-text {
  font-size: 16px !important;
  font-weight: bold !important;
}

.ag-row {
  font-size: 16px !important;
  height: 35px !important;
  color: #6666FF !important;
}

.ag-cell {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

.ag-theme-bootstrap .ag-ltr .ag-cell {
  border: none !important;
}

.ag-row:hover {
  background-color: #6666FF !important;
  color: white !important;

}

.ag-row-selected {
  background-color: #6666FF !important;
  color: white !important;
}

.ag-theme-bootstrap {
  border: 1px solid #ccc !important;
}

.ag-header-cell:not(:first-child) {
  border-left: 1px solid #ddd !important;
}</style>
